import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { TemplateProject } from '../../templates';
import { ProjectImage, ProjectImageDouble } from '../../components';
export const meta = {
  title: 'Tahometer',
  hint: 'Tahometer SaaS application for time-tracking',
  order: 48,
  type: 'project',
  published: true,
  seoTitle: 'Tahometer',
  seoDescription: 'Tahometer - UX design for SaaS time-tracking tool for large teams in a remote environment.'
};
export const content = {
  images: {
    hero: {
      src: '/images/tahometer/tahometer-intro.jpg',
      alt: 'SaaS Time Tracking Tool for Large Teams | Tahometer',
      density: [1, 2, 4],
      breakpoint: ['s', 'm', 'l']
    },
    bottom: {
      src: '/images/tahometer/tahometer-end.jpg',
      alt: 'Responsive Design - UX Design | Tahometer',
      density: [1, 2, 4],
      breakpoint: ['s', 'm', 'l']
    },
    thumbnail: {
      src: '/images/tahometer/tahometer-thumb.jpg',
      alt: 'Tahometer',
      density: [1, 2, 4]
    }
  },
  tags: ['SaaS', 'redesign', 'responsive', 'UX', 'UI', 'productivity'],
  excerpt: 'Tahometer is a time-tracking tool for large teams working remotely. We redesigned it completely and added new features such as productivity charts for fast timesheet reviews.',
  description: 'Tahometer is a time-tracking tool for large teams working remotely. We redesigned it completely simplifying existing user flows and added new features such as productivity charts based on the type of application used plus custom reports for faster employee timesheet review.',
  problem: 'In the old version, employees couldn’t easily check how many hours of work they’d recorded in the system. Managers struggled with time-consuming timesheet reviews. In addition, an unresponsive interface prevented the Tahometer team from introducing new features.',
  solution: 'First, we introduced a Summary section that provides a quick overview of recorded hours for both team leaders and regular users. Second, we improved the review process with a productivity chart and rich, customizable reports. Finally, we introduced a mobile-first, responsive interface based on our new design system.',
  figures: [{
    value: '5',
    label: 'Supported platforms'
  }, {
    value: '165',
    label: 'Designed views'
  }, {
    value: '48',
    label: 'Designed UI components'
  }, {
    value: '+17',
    label: 'New features introduced'
  }]
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  content,
  _frontmatter
};
const MDXLayout = TemplateProject;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBAX/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQME/9oADAMBAAIQAxAAAAHaVGwJAkpn/8QAGhABAAIDAQAAAAAAAAAAAAAAAQIDERITI//aAAgBAQABBQLUkVV6RlWLG30XB0cf/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEREv/aAAgBAwEBPwFqDxT/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8BTX//xAAZEAEBAQADAAAAAAAAAAAAAAAAARESIUH/2gAIAQEABj8CyvK1xqu3/8QAGRABAQEBAQEAAAAAAAAAAAAAAREAQSFR/9oACAEBAAE/IVEvE1yqc1qovzegCcwRPMACF3//2gAMAwEAAgADAAAAEC8f/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAEhETH/2gAIAQMBAT8QS16PtD//xAAXEQEBAQEAAAAAAAAAAAAAAAABACEx/9oACAECAQE/EBXlhf/EAB0QAQACAgIDAAAAAAAAAAAAAAEAESFRMUFhcaH/2gAIAQEAAT8Q4HGSjUPqCQXW8+ddTGgLTAjpiKEDft+RSyiTEeABVtrP/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/bbea80f783ef9c836df37f8fc1356cba/d2432/tahometer-problems.webp 314w", "/static/bbea80f783ef9c836df37f8fc1356cba/41786/tahometer-problems.webp 628w", "/static/bbea80f783ef9c836df37f8fc1356cba/d4599/tahometer-problems.webp 1256w", "/static/bbea80f783ef9c836df37f8fc1356cba/72bc3/tahometer-problems.webp 1884w", "/static/bbea80f783ef9c836df37f8fc1356cba/51747/tahometer-problems.webp 2512w", "/static/bbea80f783ef9c836df37f8fc1356cba/4e71a/tahometer-problems.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/bbea80f783ef9c836df37f8fc1356cba/7a743/tahometer-problems.jpg 314w", "/static/bbea80f783ef9c836df37f8fc1356cba/6f105/tahometer-problems.jpg 628w", "/static/bbea80f783ef9c836df37f8fc1356cba/10d53/tahometer-problems.jpg 1256w", "/static/bbea80f783ef9c836df37f8fc1356cba/b2e4b/tahometer-problems.jpg 1884w", "/static/bbea80f783ef9c836df37f8fc1356cba/fdcb3/tahometer-problems.jpg 2512w", "/static/bbea80f783ef9c836df37f8fc1356cba/78bd4/tahometer-problems.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/bbea80f783ef9c836df37f8fc1356cba/10d53/tahometer-problems.jpg",
            "alt": "Design Problem - UX Design | Tahometer",
            "title": "Design Problem - UX Design | Tahometer",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAwT/2gAMAwEAAhADEAAAAd4SEoClcf8A/8QAGBAAAgMAAAAAAAAAAAAAAAAAAhADESL/2gAIAQEAAQUCWXIdl//EABcRAAMBAAAAAAAAAAAAAAAAAAACEQH/2gAIAQMBAT8Bo2pT/8QAFREBAQAAAAAAAAAAAAAAAAAAAAH/2gAIAQIBAT8BR//EABkQAAEFAAAAAAAAAAAAAAAAAAEQESAiYf/aAAgBAQAGPwJKktsP/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARIUEx/9oACAEBAAE/Ie+xaLWW9totl6LyI+GZP//aAAwDAQACAAMAAAAQEN//xAAVEQEBAAAAAAAAAAAAAAAAAAABAP/aAAgBAwEBPxCIaX//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAECAQE/EII5rP/EABwQAQEAAwEAAwAAAAAAAAAAAAERACExQWFxkf/aAAgBAQABPxAWyu81gtoFfzCyoLAiN5vzNzz7xrdtSfOO0jVPSuf/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/3b1f850725af044afd43401ecabbe0e3/d2432/tahometer-scope.webp 314w", "/static/3b1f850725af044afd43401ecabbe0e3/41786/tahometer-scope.webp 628w", "/static/3b1f850725af044afd43401ecabbe0e3/d4599/tahometer-scope.webp 1256w", "/static/3b1f850725af044afd43401ecabbe0e3/72bc3/tahometer-scope.webp 1884w", "/static/3b1f850725af044afd43401ecabbe0e3/51747/tahometer-scope.webp 2512w", "/static/3b1f850725af044afd43401ecabbe0e3/4e71a/tahometer-scope.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/3b1f850725af044afd43401ecabbe0e3/7a743/tahometer-scope.jpg 314w", "/static/3b1f850725af044afd43401ecabbe0e3/6f105/tahometer-scope.jpg 628w", "/static/3b1f850725af044afd43401ecabbe0e3/10d53/tahometer-scope.jpg 1256w", "/static/3b1f850725af044afd43401ecabbe0e3/b2e4b/tahometer-scope.jpg 1884w", "/static/3b1f850725af044afd43401ecabbe0e3/fdcb3/tahometer-scope.jpg 2512w", "/static/3b1f850725af044afd43401ecabbe0e3/78bd4/tahometer-scope.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/3b1f850725af044afd43401ecabbe0e3/10d53/tahometer-scope.jpg",
            "alt": "Project Scope - UX Design | Tahometer",
            "title": "Project Scope - UX Design | Tahometer",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABQAB/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAF7DK8VYeX/xAAaEAACAgMAAAAAAAAAAAAAAAAAAgEEEBMU/9oACAEBAAEFAs73JssdDn//xAAXEQADAQAAAAAAAAAAAAAAAAAAARMC/9oACAEDAQE/Aa5KI//EABcRAAMBAAAAAAAAAAAAAAAAAAABEhP/2gAIAQIBAT8BhmbP/8QAGBAAAwEBAAAAAAAAAAAAAAAAARAxAJH/2gAIAQEABj8CdHNAv//EAB0QAAIABwEAAAAAAAAAAAAAAAABEBEhQVFhcZH/2gAIAQEAAT8hF1wvLgJGiHkWvw//2gAMAwEAAgADAAAAEHwP/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8QEn//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/EBf/xAAcEAEAAgIDAQAAAAAAAAAAAAABABExYSFBUYH/2gAIAQEAAT8QXJjcsWqG6g2QRsD4TjfCBYM77RP/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/3b9d0875c4b1fe9dac49c64a525ec10e/d2432/tahometer-timeline.webp 314w", "/static/3b9d0875c4b1fe9dac49c64a525ec10e/41786/tahometer-timeline.webp 628w", "/static/3b9d0875c4b1fe9dac49c64a525ec10e/d4599/tahometer-timeline.webp 1256w", "/static/3b9d0875c4b1fe9dac49c64a525ec10e/72bc3/tahometer-timeline.webp 1884w", "/static/3b9d0875c4b1fe9dac49c64a525ec10e/51747/tahometer-timeline.webp 2512w", "/static/3b9d0875c4b1fe9dac49c64a525ec10e/4e71a/tahometer-timeline.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/3b9d0875c4b1fe9dac49c64a525ec10e/7a743/tahometer-timeline.jpg 314w", "/static/3b9d0875c4b1fe9dac49c64a525ec10e/6f105/tahometer-timeline.jpg 628w", "/static/3b9d0875c4b1fe9dac49c64a525ec10e/10d53/tahometer-timeline.jpg 1256w", "/static/3b9d0875c4b1fe9dac49c64a525ec10e/b2e4b/tahometer-timeline.jpg 1884w", "/static/3b9d0875c4b1fe9dac49c64a525ec10e/fdcb3/tahometer-timeline.jpg 2512w", "/static/3b9d0875c4b1fe9dac49c64a525ec10e/78bd4/tahometer-timeline.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/3b9d0875c4b1fe9dac49c64a525ec10e/10d53/tahometer-timeline.jpg",
            "alt": "Workflow and Timeline - UX Design | Tahometer",
            "title": "Workflow and Timeline - UX Design | Tahometer",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAd2Wias2jRH/xAAZEAADAQEBAAAAAAAAAAAAAAAAAQISEyH/2gAIAQEAAQUCIlM5opPMVmV6v//EABgRAAMBAQAAAAAAAAAAAAAAAAACERIh/9oACAEDAQE/AWl6YU//xAAXEQEAAwAAAAAAAAAAAAAAAAAAAREx/9oACAECAQE/AYxb/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAEREDH/2gAIAQEABj8CLjnSNOlh/8QAHBABAAIBBQAAAAAAAAAAAAAAAQARgSExQWGh/9oACAEBAAE/IcsvN5bokbODEaT6R/LkVVhP/9oADAMBAAIAAwAAABC4P//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8QRljC2w//xAAWEQEBAQAAAAAAAAAAAAAAAAABEQD/2gAIAQIBAT8QDU1G/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFBwaH/2gAIAQEAAT8QzbgL6EZdGAsG9xqdmaNISMZknyAlgtor2OAB414z/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/9497aedd5efaeef7f047d992e234c52e/d2432/tahometer-wireframe.webp 314w", "/static/9497aedd5efaeef7f047d992e234c52e/41786/tahometer-wireframe.webp 628w", "/static/9497aedd5efaeef7f047d992e234c52e/d4599/tahometer-wireframe.webp 1256w", "/static/9497aedd5efaeef7f047d992e234c52e/72bc3/tahometer-wireframe.webp 1884w", "/static/9497aedd5efaeef7f047d992e234c52e/51747/tahometer-wireframe.webp 2512w", "/static/9497aedd5efaeef7f047d992e234c52e/4e71a/tahometer-wireframe.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/9497aedd5efaeef7f047d992e234c52e/7a743/tahometer-wireframe.jpg 314w", "/static/9497aedd5efaeef7f047d992e234c52e/6f105/tahometer-wireframe.jpg 628w", "/static/9497aedd5efaeef7f047d992e234c52e/10d53/tahometer-wireframe.jpg 1256w", "/static/9497aedd5efaeef7f047d992e234c52e/b2e4b/tahometer-wireframe.jpg 1884w", "/static/9497aedd5efaeef7f047d992e234c52e/fdcb3/tahometer-wireframe.jpg 2512w", "/static/9497aedd5efaeef7f047d992e234c52e/78bd4/tahometer-wireframe.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/9497aedd5efaeef7f047d992e234c52e/10d53/tahometer-wireframe.jpg",
            "alt": "Low Fidelity Prototype and Wireframes - UX Design | Tahometer",
            "title": "Low Fidelity Prototype and Wireframes - UX Design | Tahometer",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABYBAQEBAAAAAAAAAAAAAAAAAAIBBP/aAAwDAQACEAMQAAABrpVrygso/8QAGhAAAgIDAAAAAAAAAAAAAAAAAREAAgMSIf/aAAgBAQABBQKkXEZUrHuEbs//xAAYEQEAAwEAAAAAAAAAAAAAAAABAAIRIf/aAAgBAwEBPwEwOxsbP//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/AWf/xAAbEAACAQUAAAAAAAAAAAAAAAAAASECEDFhgf/aAAgBAQAGPwJQZJKXs7b/xAAaEAADAQEBAQAAAAAAAAAAAAAAAREhMUFh/9oACAEBAAE/IUr2l6N20S80lkNlUrXgk0+obnD/2gAMAwEAAgADAAAAELvv/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAERkaH/2gAIAQMBAT8QW8wYaD//xAAVEQEBAAAAAAAAAAAAAAAAAAABAP/aAAgBAgEBPxBKwN//xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhQTFR/9oACAEBAAE/EGUguOMq2BQLZTFD6nkJWFlM4w1EcF8llm3Cf//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/7fb15b70fb074cf4d191f16dac9b66b1/d2432/tahometer-flow.webp 314w", "/static/7fb15b70fb074cf4d191f16dac9b66b1/41786/tahometer-flow.webp 628w", "/static/7fb15b70fb074cf4d191f16dac9b66b1/d4599/tahometer-flow.webp 1256w", "/static/7fb15b70fb074cf4d191f16dac9b66b1/72bc3/tahometer-flow.webp 1884w", "/static/7fb15b70fb074cf4d191f16dac9b66b1/51747/tahometer-flow.webp 2512w", "/static/7fb15b70fb074cf4d191f16dac9b66b1/c31c3/tahometer-flow.webp 4000w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/7fb15b70fb074cf4d191f16dac9b66b1/7a743/tahometer-flow.jpg 314w", "/static/7fb15b70fb074cf4d191f16dac9b66b1/6f105/tahometer-flow.jpg 628w", "/static/7fb15b70fb074cf4d191f16dac9b66b1/10d53/tahometer-flow.jpg 1256w", "/static/7fb15b70fb074cf4d191f16dac9b66b1/b2e4b/tahometer-flow.jpg 1884w", "/static/7fb15b70fb074cf4d191f16dac9b66b1/fdcb3/tahometer-flow.jpg 2512w", "/static/7fb15b70fb074cf4d191f16dac9b66b1/5e632/tahometer-flow.jpg 4000w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/7fb15b70fb074cf4d191f16dac9b66b1/10d53/tahometer-flow.jpg",
            "alt": "User Journey Flow - UX Design | Tahometer",
            "title": "User Journey Flow - UX Design | Tahometer",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAAB3hMJYFhf/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAIRARD/2gAIAQEAAQUC3ta1yn//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwGq/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bh//EABcQAAMBAAAAAAAAAAAAAAAAAAAQMQH/2gAIAQEABj8CcJi//8QAHhAAAgEDBQAAAAAAAAAAAAAAAAFBEBEhMVFhcZH/2gAIAQEAAT8hbAu6PSJa+8D4rE+ieT//2gAMAwEAAgADAAAAEKvv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EALYv//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxDSR//EABwQAQADAAIDAAAAAAAAAAAAAAEAESExcVFhsf/aAAgBAQABPxBBUK+pY1T3DSZS+ddY57uW0HppfN/BKmK3K8T/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/f90365d8181f0f025f1e0ad0ffffaf7d/d2432/tahometer-palette.webp 314w", "/static/f90365d8181f0f025f1e0ad0ffffaf7d/41786/tahometer-palette.webp 628w", "/static/f90365d8181f0f025f1e0ad0ffffaf7d/d4599/tahometer-palette.webp 1256w", "/static/f90365d8181f0f025f1e0ad0ffffaf7d/72bc3/tahometer-palette.webp 1884w", "/static/f90365d8181f0f025f1e0ad0ffffaf7d/51747/tahometer-palette.webp 2512w", "/static/f90365d8181f0f025f1e0ad0ffffaf7d/4e71a/tahometer-palette.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/f90365d8181f0f025f1e0ad0ffffaf7d/7a743/tahometer-palette.jpg 314w", "/static/f90365d8181f0f025f1e0ad0ffffaf7d/6f105/tahometer-palette.jpg 628w", "/static/f90365d8181f0f025f1e0ad0ffffaf7d/10d53/tahometer-palette.jpg 1256w", "/static/f90365d8181f0f025f1e0ad0ffffaf7d/b2e4b/tahometer-palette.jpg 1884w", "/static/f90365d8181f0f025f1e0ad0ffffaf7d/fdcb3/tahometer-palette.jpg 2512w", "/static/f90365d8181f0f025f1e0ad0ffffaf7d/78bd4/tahometer-palette.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/f90365d8181f0f025f1e0ad0ffffaf7d/10d53/tahometer-palette.jpg",
            "alt": "UI Color Palette - UX Design | Tahometer",
            "title": "UI Color Palette - UX Design | Tahometer",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUBAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAACAQP/2gAMAwEAAhADEAAAAY3aV2CMein/xAAbEAACAgMBAAAAAAAAAAAAAAAAAQMTEBEhIv/aAAgBAQABBQKKKxUYfk6jSP/EABURAQEAAAAAAAAAAAAAAAAAAAAS/9oACAEDAQE/AZS//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEDE//aAAgBAgEBPwF1Zsf/xAAcEAABAwUAAAAAAAAAAAAAAAAAARAxESEiceH/2gAIAQEABj8CVawdaC2WyD//xAAbEAADAAMBAQAAAAAAAAAAAAAAAREhMZFhcf/aAAgBAQABPyHQxssFTG/gipDKcIdslwh4OH//2gAMAwEAAgADAAAAEK//AP/EABYRAQEBAAAAAAAAAAAAAAAAAAEQMf/aAAgBAwEBPxAGT//EABgRAQEAAwAAAAAAAAAAAAAAAAEAESFx/9oACAECAQE/EGuTRc3/xAAcEAEAAwACAwAAAAAAAAAAAAABABEhMVFhceH/2gAIAQEAAT8QASpDG35YV8HT6RBF3SkbGLzVE9CVo4fRRsKNv9J//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/08a695267763462f6bd93debe8052fa9/d2432/tahometer-components.webp 314w", "/static/08a695267763462f6bd93debe8052fa9/41786/tahometer-components.webp 628w", "/static/08a695267763462f6bd93debe8052fa9/d4599/tahometer-components.webp 1256w", "/static/08a695267763462f6bd93debe8052fa9/72bc3/tahometer-components.webp 1884w", "/static/08a695267763462f6bd93debe8052fa9/51747/tahometer-components.webp 2512w", "/static/08a695267763462f6bd93debe8052fa9/4e71a/tahometer-components.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/08a695267763462f6bd93debe8052fa9/7a743/tahometer-components.jpg 314w", "/static/08a695267763462f6bd93debe8052fa9/6f105/tahometer-components.jpg 628w", "/static/08a695267763462f6bd93debe8052fa9/10d53/tahometer-components.jpg 1256w", "/static/08a695267763462f6bd93debe8052fa9/b2e4b/tahometer-components.jpg 1884w", "/static/08a695267763462f6bd93debe8052fa9/fdcb3/tahometer-components.jpg 2512w", "/static/08a695267763462f6bd93debe8052fa9/78bd4/tahometer-components.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/08a695267763462f6bd93debe8052fa9/10d53/tahometer-components.jpg",
            "alt": "UI Component Design - UX Design | Tahometer",
            "title": "UI Component Design - UX Design | Tahometer",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAED/9oADAMBAAIQAxAAAAGt3pjmmsB//8QAGxAAAQQDAAAAAAAAAAAAAAAAAAECERMDEiH/2gAIAQEAAQUCqUTDJU8ckmvT/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAETAv/aAAgBAwEBPwGWSKP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAXEAADAQAAAAAAAAAAAAAAAAAQICEx/9oACAEBAAY/ArBif//EABoQAQEBAQADAAAAAAAAAAAAAAERACExQVH/2gAIAQEAAT8hciILL3fJueY4Rd5Pa48b/9oADAMBAAIAAwAAABBA7//EABYRAQEBAAAAAAAAAAAAAAAAAAEAYf/aAAgBAwEBPxAK3b//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxBX/8QAHRABAQACAgMBAAAAAAAAAAAAAREAITFhUZGhsf/aAAgBAQABPxCxTpxU39xRGcMtPfeN2JdNN4MIFrtPxyXXxCG67mCA8Z//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/7086c71966c36b0c99308005f72967fa/d2432/tahometer-hifi-1.webp 314w", "/static/7086c71966c36b0c99308005f72967fa/41786/tahometer-hifi-1.webp 628w", "/static/7086c71966c36b0c99308005f72967fa/d4599/tahometer-hifi-1.webp 1256w", "/static/7086c71966c36b0c99308005f72967fa/72bc3/tahometer-hifi-1.webp 1884w", "/static/7086c71966c36b0c99308005f72967fa/51747/tahometer-hifi-1.webp 2512w", "/static/7086c71966c36b0c99308005f72967fa/4e71a/tahometer-hifi-1.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/7086c71966c36b0c99308005f72967fa/7a743/tahometer-hifi-1.jpg 314w", "/static/7086c71966c36b0c99308005f72967fa/6f105/tahometer-hifi-1.jpg 628w", "/static/7086c71966c36b0c99308005f72967fa/10d53/tahometer-hifi-1.jpg 1256w", "/static/7086c71966c36b0c99308005f72967fa/b2e4b/tahometer-hifi-1.jpg 1884w", "/static/7086c71966c36b0c99308005f72967fa/fdcb3/tahometer-hifi-1.jpg 2512w", "/static/7086c71966c36b0c99308005f72967fa/78bd4/tahometer-hifi-1.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/7086c71966c36b0c99308005f72967fa/10d53/tahometer-hifi-1.jpg",
            "alt": "User Experience Design - UX Design | Tahometer",
            "title": "User Experience Design - UX Design | Tahometer",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEDAgX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAP/2gAMAwEAAhADEAAAAe5OLeaMkP/EABsQAQACAgMAAAAAAAAAAAAAAAEAAgMREhMh/9oACAEBAAEFAvZYsnUx3yLBTeOf/8QAGREAAQUAAAAAAAAAAAAAAAAAAAEDERJB/9oACAEDAQE/AZxSjZ//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAIAQIBAT8Bi6//xAAbEAACAQUAAAAAAAAAAAAAAAAAEQEQEzEyQf/aAAgBAQAGPwIVOpEXMm0n/8QAHhABAAICAQUAAAAAAAAAAAAAAQARIUExUWFxkbH/2gAIAQEAAT8hvpPcToC+88crxvxZ3AZ/UGtRzpZ//9oADAMBAAIAAwAAABC07//EABgRAQEAAwAAAAAAAAAAAAAAAAEAEUGR/9oACAEDAQE/EEuYENdv/8QAFxEBAAMAAAAAAAAAAAAAAAAAARARIf/aAAgBAgEBPxAppB//xAAfEAEBAAEDBQEAAAAAAAAAAAABEQAhQZExUWGB0eH/2gAIAQEAAT8Q7xjpT8YD6MHXHjAyK/b8wpvQIl8uuJop3tcY9a1ABNs//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/8d7dac4ff16516db6ed1ddaf356c0f34/d2432/tahometer-hifi-2.webp 314w", "/static/8d7dac4ff16516db6ed1ddaf356c0f34/41786/tahometer-hifi-2.webp 628w", "/static/8d7dac4ff16516db6ed1ddaf356c0f34/d4599/tahometer-hifi-2.webp 1256w", "/static/8d7dac4ff16516db6ed1ddaf356c0f34/72bc3/tahometer-hifi-2.webp 1884w", "/static/8d7dac4ff16516db6ed1ddaf356c0f34/51747/tahometer-hifi-2.webp 2512w", "/static/8d7dac4ff16516db6ed1ddaf356c0f34/4e71a/tahometer-hifi-2.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/8d7dac4ff16516db6ed1ddaf356c0f34/7a743/tahometer-hifi-2.jpg 314w", "/static/8d7dac4ff16516db6ed1ddaf356c0f34/6f105/tahometer-hifi-2.jpg 628w", "/static/8d7dac4ff16516db6ed1ddaf356c0f34/10d53/tahometer-hifi-2.jpg 1256w", "/static/8d7dac4ff16516db6ed1ddaf356c0f34/b2e4b/tahometer-hifi-2.jpg 1884w", "/static/8d7dac4ff16516db6ed1ddaf356c0f34/fdcb3/tahometer-hifi-2.jpg 2512w", "/static/8d7dac4ff16516db6ed1ddaf356c0f34/78bd4/tahometer-hifi-2.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/8d7dac4ff16516db6ed1ddaf356c0f34/10d53/tahometer-hifi-2.jpg",
            "alt": "Mobile User Interface Design - UX Design | Tahometer",
            "title": "Mobile User Interface Design - UX Design | Tahometer",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAHbSR1O0wxj/8QAGRAAAgMBAAAAAAAAAAAAAAAAAQIAAxES/9oACAEBAAEFAi2TuA7FtHLXVMHzr//EABcRAQEBAQAAAAAAAAAAAAAAAAEAAxL/2gAIAQMBAT8B7Gdb/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAESE//aAAgBAgEBPwGss3//xAAdEAABAwUBAAAAAAAAAAAAAAAAAQIREBIiMWFR/9oACAEBAAY/AjntER09LXSYaP/EABsQAAICAwEAAAAAAAAAAAAAAAERADEhQYFR/9oACAEBAAE/IVWano9qgQbHDHsbNoUsA6UYZw1n/9oADAMBAAIAAwAAABDIH//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEUH/2gAIAQMBAT8QOiwcC//EABcRAQEBAQAAAAAAAAAAAAAAABEAAUH/2gAIAQIBAT8Q4Fixb//EABoQAQADAQEBAAAAAAAAAAAAAAEAESFBMXH/2gAIAQEAAT8QJcbVYLBCkg7BFVOcbBmOe2CvXNPZ3sNe41Rxq/Cf/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/fcdcdf7a613e782fddc9500db31d5cef/d2432/tahometer-hifi-3.webp 314w", "/static/fcdcdf7a613e782fddc9500db31d5cef/41786/tahometer-hifi-3.webp 628w", "/static/fcdcdf7a613e782fddc9500db31d5cef/d4599/tahometer-hifi-3.webp 1256w", "/static/fcdcdf7a613e782fddc9500db31d5cef/72bc3/tahometer-hifi-3.webp 1884w", "/static/fcdcdf7a613e782fddc9500db31d5cef/51747/tahometer-hifi-3.webp 2512w", "/static/fcdcdf7a613e782fddc9500db31d5cef/4e71a/tahometer-hifi-3.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/fcdcdf7a613e782fddc9500db31d5cef/7a743/tahometer-hifi-3.jpg 314w", "/static/fcdcdf7a613e782fddc9500db31d5cef/6f105/tahometer-hifi-3.jpg 628w", "/static/fcdcdf7a613e782fddc9500db31d5cef/10d53/tahometer-hifi-3.jpg 1256w", "/static/fcdcdf7a613e782fddc9500db31d5cef/b2e4b/tahometer-hifi-3.jpg 1884w", "/static/fcdcdf7a613e782fddc9500db31d5cef/fdcb3/tahometer-hifi-3.jpg 2512w", "/static/fcdcdf7a613e782fddc9500db31d5cef/78bd4/tahometer-hifi-3.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/fcdcdf7a613e782fddc9500db31d5cef/10d53/tahometer-hifi-3.jpg",
            "alt": "Desktop User Interface Design - UX Design | Tahometer",
            "title": "Desktop User Interface Design - UX Design | Tahometer",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAwX/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAHaTYm8qB0R/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAECAxES/9oACAEBAAEFAlQoo8UaS2lodP/EABgRAAMBAQAAAAAAAAAAAAAAAAABAhMx/9oACAEDAQE/AXKfTOT/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARL/2gAIAQIBAT8BjT//xAAaEAACAgMAAAAAAAAAAAAAAAAAEDKhASEx/9oACAEBAAY/AukbIWt4X//EABoQAQACAwEAAAAAAAAAAAAAAAEAESExUWH/2gAIAQEAAT8hFzgcSpl7x44rQW12AbB5Cxc//9oADAMBAAIAAwAAABBHP//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/EGZv/8QAGBEBAQADAAAAAAAAAAAAAAAAAQARIVH/2gAIAQIBAT8QSGrLl//EABsQAQACAwEBAAAAAAAAAAAAAAEAESFBoTFR/9oACAEBAAE/EMnz31OwbgOrBASuOL7UvjOvZcaq4NdhkBpLJ//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/a0d474afc4a2ea57bf8c2e44bf34c719/d2432/tahometer-hifi-4.webp 314w", "/static/a0d474afc4a2ea57bf8c2e44bf34c719/41786/tahometer-hifi-4.webp 628w", "/static/a0d474afc4a2ea57bf8c2e44bf34c719/d4599/tahometer-hifi-4.webp 1256w", "/static/a0d474afc4a2ea57bf8c2e44bf34c719/72bc3/tahometer-hifi-4.webp 1884w", "/static/a0d474afc4a2ea57bf8c2e44bf34c719/51747/tahometer-hifi-4.webp 2512w", "/static/a0d474afc4a2ea57bf8c2e44bf34c719/4e71a/tahometer-hifi-4.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/a0d474afc4a2ea57bf8c2e44bf34c719/7a743/tahometer-hifi-4.jpg 314w", "/static/a0d474afc4a2ea57bf8c2e44bf34c719/6f105/tahometer-hifi-4.jpg 628w", "/static/a0d474afc4a2ea57bf8c2e44bf34c719/10d53/tahometer-hifi-4.jpg 1256w", "/static/a0d474afc4a2ea57bf8c2e44bf34c719/b2e4b/tahometer-hifi-4.jpg 1884w", "/static/a0d474afc4a2ea57bf8c2e44bf34c719/fdcb3/tahometer-hifi-4.jpg 2512w", "/static/a0d474afc4a2ea57bf8c2e44bf34c719/78bd4/tahometer-hifi-4.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/a0d474afc4a2ea57bf8c2e44bf34c719/10d53/tahometer-hifi-4.jpg",
            "alt": "SaaS User Experience Design - UX Design | Tahometer",
            "title": "SaaS User Experience Design - UX Design | Tahometer",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAADBP/aAAwDAQACEAMQAAAB25XSAIL4f//EABoQAAIDAQEAAAAAAAAAAAAAAAECABEhAxP/2gAIAQEAAQUCvW7q0qEb4BCTZ//EABkRAAIDAQAAAAAAAAAAAAAAAAARAQISIv/aAAgBAwEBPwHlTodT/8QAFhEAAwAAAAAAAAAAAAAAAAAAECFB/9oACAECAQE/AXB//8QAGhAAAgIDAAAAAAAAAAAAAAAAAAESIQIQMf/aAAgBAQAGPwIji7ZepLo2f//EABkQAQEBAQEBAAAAAAAAAAAAAAEAESFRYf/aAAgBAQABPyFYvBvk8sdE6WiYxPQ71Z/cv//aAAwDAQACAAMAAAAQ8x//xAAYEQEBAAMAAAAAAAAAAAAAAAABABFRgf/aAAgBAwEBPxAWGXLTf//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEVH/2gAIAQIBAT8QTZM63//EABsQAQEAAgMBAAAAAAAAAAAAAAERAFEhMdFx/9oACAEBAAE/EOxUg8bnuJnMKER1DAEI0Q4mbfZHUnmRDjT6sesF1DrP/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b2c2d9aa92264530172daf6cd9b58a77/d2432/tahometer-hifi-5.webp 314w", "/static/b2c2d9aa92264530172daf6cd9b58a77/41786/tahometer-hifi-5.webp 628w", "/static/b2c2d9aa92264530172daf6cd9b58a77/d4599/tahometer-hifi-5.webp 1256w", "/static/b2c2d9aa92264530172daf6cd9b58a77/72bc3/tahometer-hifi-5.webp 1884w", "/static/b2c2d9aa92264530172daf6cd9b58a77/51747/tahometer-hifi-5.webp 2512w", "/static/b2c2d9aa92264530172daf6cd9b58a77/4e71a/tahometer-hifi-5.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b2c2d9aa92264530172daf6cd9b58a77/7a743/tahometer-hifi-5.jpg 314w", "/static/b2c2d9aa92264530172daf6cd9b58a77/6f105/tahometer-hifi-5.jpg 628w", "/static/b2c2d9aa92264530172daf6cd9b58a77/10d53/tahometer-hifi-5.jpg 1256w", "/static/b2c2d9aa92264530172daf6cd9b58a77/b2e4b/tahometer-hifi-5.jpg 1884w", "/static/b2c2d9aa92264530172daf6cd9b58a77/fdcb3/tahometer-hifi-5.jpg 2512w", "/static/b2c2d9aa92264530172daf6cd9b58a77/78bd4/tahometer-hifi-5.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/b2c2d9aa92264530172daf6cd9b58a77/10d53/tahometer-hifi-5.jpg",
            "alt": "Prototyping - UX Design | Tahometer",
            "title": "Prototyping - UX Design | Tahometer",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAP/2gAMAwEAAhADEAAAAd2BIqVjw//EABoQAAIDAQEAAAAAAAAAAAAAAAECABEhAxL/2gAIAQEAAQUClxuvktgY0Dp//8QAGBEAAwEBAAAAAAAAAAAAAAAAAAIRASH/2gAIAQMBAT8BsNZrw//EABURAQEAAAAAAAAAAAAAAAAAAAAh/9oACAECAQE/AUf/xAAYEAACAwAAAAAAAAAAAAAAAAAQEQABMf/aAAgBAQAGPwLQlLP/xAAZEAADAQEBAAAAAAAAAAAAAAAAAREhMVH/2gAIAQEAAT8hsb8GuirmZDT5CS4Ls4f/2gAMAwEAAgADAAAAEHzf/8QAFxEBAQEBAAAAAAAAAAAAAAAAAREAIf/aAAgBAwEBPxBFLr1c3//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQIBAT8QlEo1H//EABoQAQEBAQADAAAAAAAAAAAAAAERIQBRcZH/2gAIAQEAAT8QbKE0njqQANzkzSDR54eFfnQoOyPrtiDJh3//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/20e6da2e0e93b8e5c3acdd20a4d37b40/d2432/tahometer-handoff.webp 314w", "/static/20e6da2e0e93b8e5c3acdd20a4d37b40/41786/tahometer-handoff.webp 628w", "/static/20e6da2e0e93b8e5c3acdd20a4d37b40/d4599/tahometer-handoff.webp 1256w", "/static/20e6da2e0e93b8e5c3acdd20a4d37b40/72bc3/tahometer-handoff.webp 1884w", "/static/20e6da2e0e93b8e5c3acdd20a4d37b40/51747/tahometer-handoff.webp 2512w", "/static/20e6da2e0e93b8e5c3acdd20a4d37b40/4e71a/tahometer-handoff.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/20e6da2e0e93b8e5c3acdd20a4d37b40/7a743/tahometer-handoff.jpg 314w", "/static/20e6da2e0e93b8e5c3acdd20a4d37b40/6f105/tahometer-handoff.jpg 628w", "/static/20e6da2e0e93b8e5c3acdd20a4d37b40/10d53/tahometer-handoff.jpg 1256w", "/static/20e6da2e0e93b8e5c3acdd20a4d37b40/b2e4b/tahometer-handoff.jpg 1884w", "/static/20e6da2e0e93b8e5c3acdd20a4d37b40/fdcb3/tahometer-handoff.jpg 2512w", "/static/20e6da2e0e93b8e5c3acdd20a4d37b40/78bd4/tahometer-handoff.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/20e6da2e0e93b8e5c3acdd20a4d37b40/10d53/tahometer-handoff.jpg",
            "alt": "Handoff and Style Guide - UX Design | Tahometer",
            "title": "Handoff and Style Guide - UX Design | Tahometer",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      